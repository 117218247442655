@import '@/scss/variables.scss'
@import '~vuetify/src/styles/styles.sass'

.cell-overflow
  white-space: nowrap

  width: 15rem

  overflow: hidden

  text-overflow: ellipsis
